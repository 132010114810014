import * as React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { responsiveImage } from '../../../utils/helpers.js'
import { MDBContainer } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import get from 'lodash/get'
import { kebabCase } from 'lodash'

const TagsPage = (props, location) => {
  const posts = get(props, 'data.allMarkdownRemark')
  const heroImage = get(props, 'data.heroImage')
  const hero = responsiveImage(heroImage.childImageSharp.fluid.srcSetWebp)

  const site = get(props, 'data.site')    

  return (
      <Layout>
        <div id="sub-page">
          <SEO
            title={`UNICOM Innovation Center tags`}
            description={`Drill down into UNICOM Innovation Center blogs by blog category/tag`}
            url={site.siteMetadata.siteUrl + location.pathname}
            image={site.siteMetadata.siteUrl + hero}          
          />

          <Hero
            class="intro-50"
            image={hero}
            title="UNICOM Innovation Center"
            subtitle="Design & architect your digital transformation."
            type="blog"
          />

          <main>
            <section id="main" className="bg-white">
              <MDBContainer>
              <div className="columns">
                <div
                  className="column is-10 is-offset-1"
                  style={{ marginBottom: "6rem" }}
                >
                  <p className="font-alt font-w-300 letter-spacing-1 text-medium"><Link to="/blog/" className="effect">Blog</Link> / Tags</p>
                  <ul className="tag-list pt-3">
                    {posts.group.map((tag) => (
                      <li key={tag.fieldValue} className="py-2">
                        <Link to={`/blog/tags/${kebabCase(tag.fieldValue)}/`} className="effect">
                          {tag.fieldValue} ({tag.totalCount})
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </MDBContainer>
            </section>
          </main>
        </div>
      </Layout>
  )
}
export default TagsPage

export const tagPageQuery = graphql`
  query TagsListQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    heroImage: file(name: { eq: "innovation-center-banner" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`